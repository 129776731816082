define("shop/components/product-item", ["exports", "ember-in-viewport"], function (_exports, _emberInViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberInViewport.default, {
    condensed: false,
    imageSize: 'md',
    media: Ember.inject.service(),
    mainImage: Ember.computed('product.images.[]', function () {
      return this.product.plungedImage || this.product.image;
    }),
    hoverImage: Ember.computed('product.images.[]', 'gender', function () {
      return this.product.get(this.gender + 'Image');
    }),
    didEnterViewport: function didEnterViewport() {
      this._super.apply(this, arguments);

      if (!this.enteredViewport) {
        this.set('enteredViewport', true);
      }
    }
  });

  _exports.default = _default;
});