define("shop/templates/components/press-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kYM2Wb8L",
    "block": "{\"symbols\":[\"article\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex flex-wrap -mx-6 mb-12 lg:mb-24\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"articles\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"w-full lg:w-1/4 px-6 mb-12\"],[10,\"data-content\",\"\"],[8],[0,\"\\n      \"],[7,\"a\",true],[11,\"href\",[23,1,[\"link\"]]],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[10,\"class\",\"block\"],[8],[0,\"\\n        \"],[7,\"img\",true],[11,\"src\",[23,1,[\"cover\"]]],[10,\"class\",\"block w-full mb-6\"],[10,\"alt\",\"\"],[8],[9],[0,\"\\n        \"],[7,\"h2\",true],[10,\"class\",\"text-lg lg:text-xl leading-tight font-medium mb-2\"],[8],[0,\"\\n          \"],[1,[23,1,[\"title\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"text-grey-dark\"],[8],[0,\"\\n          \"],[1,[28,\"format-date\",[[23,1,[\"date\"]]],[[\"format\"],[\"date\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/press-list.hbs"
    }
  });

  _exports.default = _default;
});