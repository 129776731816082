define("shop/models/shop", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  var SUPPORT_EMAIL = 'support@luneteyewear.com';

  var _default = Model.extend({
    title: attr('string'),
    email: attr('string'),
    currency: attr('string'),
    conversionRate: attr('number', {
      defaultValue: 1
    }),
    vatRate: attr('number', {
      defaultValue: 0
    }),
    reducedVatRate: attr('number', {
      defaultValue: 0
    }),
    htoLimit: attr('number', {
      defaultValue: 0
    }),
    locale: attr('string', {
      defaultValue: 'en'
    }),
    locales: attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    countryCode: attr('string'),
    tags: attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    timeZoneName: attr('string'),
    basePrice: attr('number'),
    contactAddress: belongsTo('address'),
    billingAddress: belongsTo('address'),
    autoDiscount: belongsTo('discount'),
    locations: hasMany('location'),
    supportEmail: SUPPORT_EMAIL,
    country: Ember.computed.readOnly('title'),
    phone: Ember.computed.readOnly('contactAddress.phone'),
    hasHomeTryOn: Ember.computed.gt('htoLimit', 0),
    hasLocations: Ember.computed.notEmpty('locations'),
    hasAutoDiscount: Ember.computed.bool('autoDiscount.id'),
    headquarters: Ember.computed.filterBy('locations', 'isHeadquarter'),
    headquarter: Ember.computed.readOnly('headquarters.firstObject'),
    hasGiftCards: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('gifts');
    }),
    hasPaymentIssues: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('payment_issues');
    }),
    hasPublicDiscount: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('public_discount');
    }),
    hasArchiveSale: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('archive_sale');
    })
  });

  _exports.default = _default;
});