define("shop/router", ["exports", "ember-router-scroll", "shop/config/environment"], function (_exports, _emberRouterScroll, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.on('routeDidChange', function () {
        _this.metrics.trackPage({
          page: _this.url
        });
      });
    }
  });
  Router.map(function () {
    this.route('shop', {
      path: '/:locale'
    }, function () {
      this.route('products', {
        path: '/:category',
        resetNamespace: true
      }, function () {
        this.route('index', {
          path: '/:gender'
        });
        this.route('product', {
          path: '/:title/:variant'
        });
      });
      this.route('accessories', {
        path: '/accessories/:category',
        resetNamespace: true
      });
      this.route('checkout', {
        resetNamespace: true
      }, function () {
        this.route('shipping');
        this.route('billing', function () {
          this.route('edit');
        });
        this.route('prescription', function () {
          this.route('edit');
          this.route('upload');
        });
        this.route('payment');
      });
      this.route('archive', {
        path: '/archive/:category',
        resetNamespace: true
      });
      this.route('shops', {
        resetNamespace: true
      });
      this.route('login', {
        resetNamespace: true
      });
      this.route('home-try-on', {
        resetNamespace: true
      });
      this.route('account', {
        resetNamespace: true
      }, function () {});
      this.route('order', {
        path: '/orders/:order_id',
        resetNamespace: true
      }, function () {
        this.route('feedback');
        this.route('shipping');
      });
      this.route('appointment', {
        path: '/appointments/:appointment_id',
        resetNamespace: true
      });
      this.route('page', {
        path: '/:slug',
        resetNamespace: true
      });
      this.route('about', {
        resetNamespace: true
      }, function () {});
      this.route('journal', {
        resetNamespace: true
      }, function () {
        this.route('article', {
          path: '/:slug'
        });
      });
      this.route('press', {
        resetNamespace: true
      });
      this.route('gift-card', {
        resetNamespace: true
      });
      this.route('eye-test', {
        resetNamespace: true
      });
      this.route('stores', {
        resetNamespace: true
      }, function () {
        this.route('location', {
          path: '/:slug'
        });
      });
      this.route('reviews', {
        resetNamespace: true
      });
      this.route('jobs', {
        resetNamespace: true
      }, function () {
        this.route('job', {
          path: '/:slug'
        });
      });
      this.route('frame-your-story', {
        resetNamespace: true
      });
    });
    this.route('payment', function () {
      this.route('success');
      this.route('failed');
    });
  });
  var _default = Router;
  _exports.default = _default;
});